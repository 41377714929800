import { people_thumbs } from "./gen_people";
import { person_to_asset_map } from "./gen_all_images";

function thumbUrl(url) {
  return `thumbs/${url}`;
}

export function idFromThumbUrl(url) {
  const one = url.substr("thumbs/".length);
  // strip extension
  return one.substr(0, one.lastIndexOf("."));
}

const breakpoints = [];

export const thumbs = people_thumbs.map((photo) => ({
  src: thumbUrl(photo.url),
  width: photo.length,
  height: photo.length,
  srcSet: breakpoints.map((breakpoint) => {
    const height = Math.round((photo.height / photo.width) * breakpoint);
    return {
      src: thumbUrl(photo.url),
      width: breakpoint,
      height,
    };
  }),
}));

function allImageUrl(url) {
  return `/resized_images/${url}`;
}

export function get_images_for_person(person_id, filter) {
  return (person_to_asset_map[person_id] || [])
    .filter((image) => {
      if (!filter) {
        return true;
      }
      return image.url.toLocaleLowerCase().includes(filter) || image.originalUrl.toLocaleLowerCase().includes(filter);
    })
    .map((image) => {
      return {
        src: allImageUrl(image.url),
        width: image.width,
        height: image.height,
        //       srcSet: [ { src: image.originalUrl, width: image.originalWidth, height: image.originalHeight } ],
        download: { url: image.originalUrl + "?d=1" },
      };
    })
    .reverse();
}
