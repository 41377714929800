import "./App.css";
import { useState } from "react";
import React from "react";
import YouTube from 'react-youtube';
import { useParams, useNavigate } from "react-router-dom";

import { thumbs, idFromThumbUrl, get_images_for_person } from "./photos";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Download from "yet-another-react-lightbox/plugins/download";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export function Main() {
  return (
    <>
      <div>
        Videos: <a href="/videos">/videos</a>
      </div>
    </>
  );
}

export function Videos() {
  const videos = [
    {
      embedId: "tJEzyP_N-Xw"
    },
    {
      embedId: "bckkP1wGNr4"
    },
    {
      embedId: "n_ziW4KpLdc"
    },
    {
      embedId: "2YbsOPSko2c"
    },
    {
      embedId: "t-BnVFwyFq8"
    },
    {
	    // haldi perf 1
  	  embedId: "ozSLZKLrVV0"
    },
    {
  	  embedId: "2Mpz-egrgKw"
    },
    {
      // dance 0
      embedId: "wUXXWDohqfI"
    },
    {
      embedId: "oIx3sfkeV-A"
    },
    {
      embedId: "-OKPjchDKNA"
    },
    {
      embedId: "NdUU0e5hjGM"
    },
    {
      embedId: "yn9gyDTQTi0"
    },
    {
      embedId: "MAJnB2fp5ho"
    },
    {
      embedId: "8XTfuXlkxuw"
    },
    {
      embedId: "VHrvLzMgYug"
    },
    {
      embedId: "JL1H_SgkxvA"
    },
    {
      embedId: "_mVeG_eEBiY"
    },
    // start of the movies
    {
      embedId: "4QKoiRkWRmI"
    },
    {
      embedId: "nOA5dMkZoMw"
    },
    {
      embedId: "2OhYTdRoWh8"
    },
    {
      embedId: "51mo2oeBgBM"
    },
    {
      embedId: "y68OOQe-cGY"
    },
    {
      embedId: "J4xdgHU_yxA"
    },
    {
      embedId: "MpSmhzLFpBo"
    },
  ]
  return (
    <>
      {videos.map((video) => {
        return (
          <>
            <YouTube videoId={video.embedId} title={video.title} />
            <br />
            <br />
          </>
        );
      })}
    </>
  );
}

export function Gallery() {
  const navigate = useNavigate();
  return (
    <>
      <div className="faces-gal" width="80%">
        <PhotoAlbum
          photos={thumbs}
          layout="rows"
          targetRowHeight={150}
          onClick={(evt) => {
            navigate(`/person/${idFromThumbUrl(evt.photo.src)}`);
          }}
        />
      </div>
    </>
  );
}

function PersonLightbox({ photos, index, setIndex }) {
  return (
    <>
      <Lightbox
        slides={photos}
        open={index > -1}
        index={index}
        zoom={{
          zoomInMultipler: 5,
          maxZoomPixelRatio: 5,
        }}
        close={() => setIndex(-1)}
        plugins={[Download, Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </>
  );
}

export function Person(props) {
  const params = useParams();
  const photos = get_images_for_person(params.personId, params.filter);
  const [index, setIndex] = useState(-1);

  return (
    <>
      <div>
        <PhotoAlbum
          photos={photos}
          layout="rows"
          onClick={(evt) => {
            setIndex(evt.index);
          }}
        />
      </div>
      <PersonLightbox photos={photos} index={index} setIndex={setIndex} />
    </>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/shyamotsav2023admin",
    element: <Gallery />,
  },
  {
    path: "/person/:personId",
    element: <Person />,
  },
  {
    path: "/person/:personId/:filter",
    element: <Person />,
  },
  {
    path: "/videos",
    element: <Videos/>,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
